.details_puja {
  margin-top: 80px;
}

.details_puja .durga_mata_image img {
  width: 100%;
  border-radius: 8px;
}

.durga_mata_image {
  position: relative;
  overflow: hidden;
}

.durga_mata_image .durga_mata_image_bg {
  position: absolute;
  top: 0;
  right: -40px;
}

/* .durga_mata_image .durga_mata_image_bg img {
height: 690px;
} */
.durga_mata_content {
  margin-top: 20px;
  margin-left: 30px;
}

/* .durga_mata_content .social-icons ul {
padding: 0;
margin: 0 !important;
border-radius: 43px;
border: 0.6px solid rgba(255, 91, 0, 0.30);
opacity: 0.8;
background: #FFF;
width: 320px;
padding: 08px 12px 05px 12px;
display: flex;
justify-content: center;
} 

.durga_mata_content .social-icons ul li a img {
width: 30px;
height: 30px;
} 
*/
/* styles */
.social-icons {
  display: flex;
  align-items: center;
}

.social-icons .MuiButton {
  padding: 0px !important;
}

.social-icons .social-icons-main {
  border-radius: 43px;
  border: 0.6px solid rgba(255, 91, 0, 0.3);
  background: #fff;
  padding: 08px 7px 05px 7px !important;
}

.social-icons .social-icons-main .expandedIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  width: 250px;
}

.social-icons .social-icons-main .expandedIcons a img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.social-icons .expanded {
  display: flex;
  align-items: end;
  flex-direction: row;
  padding: 08px 12px 05px 12px !important;
}

/* styles */
.durga_mata_content h3 {
  color: #2c0202;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  margin: 30px 0px 10px 0px;
  width: 540px;
}

.date_location_event {
  margin-top: 40px;
  margin-left: 20px;
}

.date_location_event p {
  color: #2c0202e3 !important;
  font-size: 16px;
  font-style: normal;
  font-family: 500;
  line-height: 26px;
  margin: 0px 0px 20px 0px;
}

.date_location_event p span {
  font-size: 15px;
  line-height: 25px;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.date_location_event .date {
  position: relative;
}

.date_location_event .date::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/date.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 19px;
  height: 19px;
  top: 04px;
  left: -28px;
}

.date_location_event .location {
  position: relative;
}

.date_location_event .location::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/location.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 19px;
  height: 19px;
  top: 04px;
  left: -28px;
}

.date_location_event .event {
  position: relative;
  margin: 0;
}

.date_location_event .event::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/event.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 19px;
  height: 19px;
  top: 04px;
  left: -28px;
}

.new_details_pujas .date_location_event .location::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/icons/cause.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 22px;
  height: 22px;
  top: 04px;
  left: -28px;
}

.book_seva_button {
  margin-top: 40px;
}

.book_seva_button a button {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  border-radius: 06px;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
  text-transform: none;
  padding: 10px 30px;
}

.book_seva_button a button img {
  margin-left: 10px;
}

.book_seva_button-1 button {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  border-radius: 06px;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
  text-transform: none;
  padding: 10px 30px;
  margin-top: 14px;
}

/* scrollspy */
.champaign-tab {
  margin-top: 50px;
  z-index: -99;
}

.champaign-tab .nav {
  position: sticky;
  top: 65px;
  display: flex;
  justify-content: center;
  z-index: 99;
}

.champaign-tab .nav .child-nav {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.8);
  background: #fff;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
  width: 735px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  /* z-index: 999; */
}

.champaign-tab .nav button {
  text-transform: capitalize;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
  color: rgba(37, 37, 37, 0.8);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.champaign-tab .nav button.active {
  color: #ffffff;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
}

.champaign-tab .section4 {
  padding: 40px 0px 60px 0px;
}

.donation_champaign_tab .section4 {
  padding-top: 110px !important ;
}

.champaign-tab .section3 {
  padding: 20px 0px 0px 0px;
}

.champaign-tab .section1 {
  padding: 20px 0px 0px 0px;
}

.champaign-tab .section2 {
  padding: 20px 0px 0px 0px;
}

.champaign-tab .section1 h2 {
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}

.donations_count {
  display: none;
  width: 100%;
}

.donations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  /* border-top-left-radius: 10px;
border-top-right-radius: 10px; */
}

.donations_count_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
  background-color: #fff;
  padding: 10px 0 10px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 999;
}

.donations .MuiButton-root {
  display: flex;
  padding: 10px 30px !important;
  align-items: center;
  border-radius: 06px;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% ), #fff;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: unset;
  width: 100%;
  max-width: 90%;
}

.donations_count_days {
  background-color: hsl(138, 87%, 40%);
  padding: 4px;
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 70px;
  position: absolute;
  top: -29px;
  z-index: -9999;
  display: flex;
  justify-content: center;
}

.donations_count_days p {
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  font-weight: 800;
  color: #fff;
}

.donations .MuiButton-root .MuiSvgIcon-root {
  margin-left: 7px;
  font-size: 20px;
}

.champaign-tab .section1 .Welcome {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(254 231 170 / 47%);
  padding: 16px;
  display: flex;
  margin-bottom: 30px;
  height: 100%;
}

.seva_cards_live_image {
  width: 350px;
  height: 200px;
}

.champaign-tab .section1 .Welcome .DurgaMaa {
  padding: 0 0 0 30px;
  width: 100%;
}

.select-menu-button .MuiMenuItem-root {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  min-height: auto;
  white-space: wrap;
}

.champaign-tab .section1 .Welcome .DurgaMaa h2 {
  color: #2c0202;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .flex {
  display: flex ;
}

.champaign-tab .section1 .Welcome .DurgaMaa h4 {
  color: #ff5b00;
  font-family:'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 15px;
}

.champaign-tab .section1 .Welcome .DurgaMaa h4:nth-child(2) {
  font-size: 12px;
  color: #838383;
  margin-left: 15px;
  text-decoration: line-through;
  font-weight: 600;
}

.champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiInputLabel-root {
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiSelect-select {
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  background: #fff;
  width: 480px;
  max-width: 480px;
  padding: 10px 22px 10px 10px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiSelect-select span:nth-child(3) {
  color: #838383;
  margin-left: 15px;
  text-decoration: line-through;
}

.champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border: unset;
}

.champaign-tab .section1 .Welcome .DurgaMaa p {
  color: rgb(18 19 23 / 88%);
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 20px 0px;
}

.champaign-tab .section1 .Welcome .DurgaMaa p span {
  color: #ff5b00;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 7px;
  cursor: pointer;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image img {
  height: 22px;
  object-fit: contain;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li {
  list-style: none;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li:not(:first-child) {
  margin-left: 10px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .book_now {
  border-radius: 06px;
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: unset;
  padding: 10px 40px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .book_now img {
  margin-left: 10px;
}

.champaign-tab .section1 .Welcome .DurgaMaa .live-content .MuiButton-root .MuiSvgIcon-root {
  margin-left: 7px;
}

.champaign-tab .section2 .video-section {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.champaign-tab .section2 .video-section h4 {
  color: #2c0202;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0px 0px 05px 0px;
  text-transform: unset;
}

.champaign-tab .section2 .video-section p {
  color: #2c0202e3 !important;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  max-width: 673px;
}

.champaign-tab .section3 .update-section {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 20px 0 #0000000d;
}

.champaign-tab .section3 .update-section:last-child {
  margin-bottom: 0 !important;
}

.champaign-tab .section3 .update-section h4 {
  color: #2c0202;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}

.champaign-tab .section3 .update-section p {
  color: #2c0202e3 !important;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

/* .champaign-tab .section3 .update-section p strong{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
} */
.champaign-tab .section4 .recomanded-sevas {
  border-radius: 25px;
  background: linear-gradient(280deg, #a82a04 1.05%, #e45705 103.64%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 100px 30px 70px 30px;
}

.champaign-tab .section4 h4 {
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}

.champaign-tab .section4 .recomanded-sevas h2 {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.3%;
  margin-bottom: 20px;
  width: 655px;
}

.champaign-tab .section4 .recomanded-sevas p {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.1%;
  width: 655px;
  margin-bottom: 20px;
}

.champaign-tab .section4 .recomanded-sevas .MuiButton-root {
  border-radius: 40.87px;
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: #e25704;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  text-transform: capitalize;
}

.live-content .live-content-image .record_button {
  display: flex;
  align-items: center;
  color: #0000d4;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.live-content .live-content-image .record_button img {
  padding-right: 10px;
}

.live-content .live-content-image .prasad {
  display: flex !important;
  align-items: center !important;
  color: #ee7e04 !important;
  font-family: "Quicksand", sans-serif;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin: 0;
}

.live-content .live-content-image .prasad img {
  padding-right: 10px;
}

.non_live-content-image ul li {
  filter: grayscale();
}

.video_section_image {
  margin-bottom: 20px;
  /* position: relative; */
}

.video_section_image .video-_section_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_section_image .video-_section_button img {
  width: 60px;
}

/* .champaign-tab .section1 .seva_card_deatils_ .Welcome .seva_cards_live_image{
width: 330px;
height: 330px;
} */
.donation_champaign_tab_one {
  padding-top: 40px;
}

.section3 .update-section p strong {
  width: 100% !important;
}

.section3 .update-section p img {
  height: 360px;
  max-width: 600px;
  width: 100% !important;
  border-radius: 10px;
  /* border-radius: 10px;
max-width: 800px;
width: 100% !important;
height: 350px !important; */
}

.updates_date {
  background: linear-gradient( 276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28% );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900 !important;
  margin-bottom: 5px !important;
}

.new_details_pujas {
  margin-bottom: 60px;
}

.new_date_location_event {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 345px) and (max-width:767px) {
  .details_puja {
      margin-top: 80px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .details_puja {
      margin-top: 65px;
  }

  .durga_mata_content {
      margin: 10px 0px 0px 0px;
      padding: 0px 16px;
  }

  .durga_mata_content .social-icons ul {
      display: flex;
      justify-content: space-between;
      width: auto;
  }

  .durga_mata_content .social-icons ul li {
      margin-left: 0px;
  }

  .durga_mata_content h3 {
      font-size: 18px;
      line-height: 24px;
      width: auto;
      margin: 0px 0px 0px 0px;
  }

  .date_location_event {
      margin-top: 10px;
      margin-left: 30px;
  }

  .date_location_event p {
      margin-bottom: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
  }

  .book_seva_button {
      margin-top: 20px;
  }

  .champaign-tab {
      margin-top: 1px;
  }

  .champaign-tab .section1 {
      padding: 0px 0px 0px 0px;
  }

  .champaign-tab .section1 h2 {
      font-size: 25px;
      line-height: 35px;
  }

  .durga_mata_image .durga_mata_image_bg img {
      width: 0;
  }

  .champaign-tab .section1 .Welcome {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      width: auto;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa h2 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa h4 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
  }

  .champaign-tab .section1 .Welcome .MuiFormControl-root {
      width: 100%;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa {
      padding: 20px 0px 0px 0px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa p {
      margin: 10px 0px 0px 0px;
  }

  .live-content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li:not(:first-child) {
      margin: 10px 0px 0px 0px;
  }

  .welcome_book_now {
      width: 100%;
      margin-top: 20px;
  }

  .welcome_book_now button {
      width: 100% !important;
  }

  .video_section_image img {
      width: 100%;
  }

  .champaign-tab .section2 .video-section p {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      width: auto;
  }

  .champaign-tab .section2 .video-section {
      padding: 10px;
  }

  .champaign-tab .section2 .video-section h4 {
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0px 5px 0px;
  }

  .champaign-tab .section2 {
      padding-top: 0px;
  }

  .champaign-tab .section3 {
      padding: 0px 0px 0px 0px;
  }

  .champaign-tab .section4 {
      padding: 40px 0px 0px 0px;
  }

  .champaign-tab .section3 .update-section {
      margin-bottom: 20px !important;
  }

  .champaign-tab .section3 .update-section h4 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 5px;
  }

  .recommnded_donations {
      padding: 20px 0px 40px 0px !important;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image {
      width: 100%;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li .MuiButton-root {
      padding: 0;
      min-width: 40px;
      margin-right: 10px;
  }

  .champaign-tab .section4 h4 {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 10px;
  }

  .champaign-tab .nav .child-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
  }

  .champaign-tab .nav button {
      padding: 5px 8px;
      font-size: 10px;
  }

  .champaign-tab .nav button.active {
      font-size: 10px;
  }

  .donation_champaign_tab_one {
      padding: 30px 0px 50px 0px;
  }

  .donation_champaign_tab_one .section4 h4 {
      margin-bottom: 20px !important;
  }

  .video_section_image {
      margin-bottom: 0;
  }

  .donations button {
      padding: 4px 60px !important;
  }

  /* .seva_cards_live_image{
    width: 100%;
} */
  .MuiMenuItem-root {
      white-space: normal !important;
  }

  .section3 .update-section p img {
      height: 220px !important;
      max-width: none !important;
      width: 100% !important;
  }

  .book_seva_button-1 {
      padding: 10px;
      position: fixed;
      bottom: -1px;
      left: 0;
      z-index: 999999;
      width: 100%;
      max-width: 100%;
      border-radius: 20px 20px 0 0;
      background-color: #fff;
      /* padding: 10px 0 10px 0; */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .book_seva_button-1 button {
      width: 100%;
      height: 34px;
      cursor: pointer;
      margin: 0px !important;
  }

  .champaign-tab .section3 .update-section iframe {
      width: 100%;
      height: 200px;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .donate {
      flex-direction: column;
      display: flex;
      width: auto;
      padding: 10px;
      border-radius: 10px;
  }

  .donate p {
      font-size: 12px;
      line-height: 22px;
      width: 290px;
      text-align: center;
  }

  .donations .donate button {
      padding: 4px 20px;
      margin-top: 10px;
  }

  .donations .donate {
      width: 280px !important;
      padding: 10px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul {
      display: flex;
      flex-direction: row !important;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li:not(:first-child) {
      margin: 0px 0px 0px 10px !important;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiSelect-select {
      width: 100%;
      max-width: unset;
  }
}

@media (min-width: 375px) and (max-width: 549px) {
  .donate {
      display: flex;
      width: auto;
      padding: 10px;
      border-radius: 10px;
  }

  .donate p {
      font-size: 12px;
      line-height: 22px;
      width: 220px;
  }

  .donations .donate button {
      padding: 4px 20px;
  }

  .donations .donate {
      width: auto;
      padding: 10px;
  }
}

@media (min-width: 550px) and (max-width: 767px) {
  .donate {
      display: flex;
      width: auto;
      padding: 10px;
      border-radius: 10px;
  }

  .donate p {
      font-size: 12px;
      line-height: 22px;
      width: 300px;
  }

  .donations .donate button {
      padding: 4px 20px;
  }

  .donations .donate {
      width: 500px;
      padding: 10px;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .donations_count {
      display: block !important;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa p span {
      font-size: 13px;
      font-weight: 600;
      line-height: 23px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .details_puja {
      margin-top: 60px;
  }

  .durga_mata_content {
      margin: 10px 0px 0px 0px;
      padding: 0px 16px;
  }

  .durga_mata_content .social-icons ul {
      display: flex;
      justify-content: space-between;
  }

  .durga_mata_content .social-icons ul li {
      margin-left: 0px;
  }

  .durga_mata_content h3 {
      font-size: 18px;
      line-height: 24px;
      width: auto;
      margin: 2px 0px 0px 0px;
  }

  .date_location_event {
      margin-top: 10px;
      margin-left: 28px;
  }

  .date_location_event p {
      margin-bottom: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
  }

  .book_seva_button {
      margin-top: 20px;
  }

  .champaign-tab {
      margin-top: 30px;
  }

  .champaign-tab .nav {
      width: 100%;
  }

  .champaign-tab .section1 {
      padding: 40px 0px 0px 0px;
  }

  .champaign-tab .section1 h2 {
      font-size: 25px;
      line-height: 35px;
  }

  .durga_mata_image .durga_mata_image_bg img {
      width: 0;
  }

  .champaign-tab .section1 .Welcome {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      width: auto;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa h2 {
      font-size: 18px;
      line-height: 28px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .MuiFormControl-root .MuiSelect-select {
      width: 100%;
      max-width: unset;
  }

  .champaign-tab .section1 .Welcome .MuiFormControl-root {
      width: 100%;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa {
      padding: 20px 0px 0px 0px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa p {
      margin: 10px 0px 0px 0px;
  }

  .live-content {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li:not(:first-child) {
      margin: 0px 0px 0px 10px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image ul li button {
      padding: 0;
  }

  .welcome_book_now {
      width: 100%;
  }

  .welcome_book_now button {
      width: 100% !important;
  }

  .video_section_image img {
      width: 100%;
  }

  .champaign-tab .section2 .video-section p {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      width: auto;
  }

  .champaign-tab .section2 .video-section {
      padding: 20px 10px;
  }

  .recommnded_donations {
      padding: 20px 0px 40px 0px !important;
  }

  .recommnded_donations h4 {
      margin-bottom: 10px !important;
  }

  .champaign-tab .section2 .video-section h4 {
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0px 5px 0px;
  }

  .champaign-tab .section2 {
      padding-top: 30px;
  }

  .champaign-tab .section3 {
      padding: 20px 0px 0px 0px;
  }

  .champaign-tab .section4 {
      padding: 40px 0px 0px 0px;
  }

  .champaign-tab .section3 .update-section {
      margin-bottom: 20px !important;
  }

  .champaign-tab .section3 .update-section h4 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 5px;
  }

  .champaign-tab .section1 .Welcome .DurgaMaa .live-content .live-content-image {
      width: 100%;
  }

  .champaign-tab .section4 h4 {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 5px;
  }

  .champaign-tab .nav .child-nav {
      width: 100%;
  }

  .champaign-tab .nav button {
      padding: 10px;
  }

  .champaign-tab .nav button {
      font-size: 12px !important;
  }

  .champaign-tab .section1 .donate p {
      text-align: center;
  }

  .special-offer-content h2 {
      font-size: 30px;
      line-height: 40px;
  }

  .champaign-tab .section3 .update-section {
      margin-bottom: 20px;
  }

  .donations button {
      padding: 4px 20px;
  }

  .donation_champaign_tab_one .section4 h4 {
      margin-bottom: 20px !important;
  }

  .welcome_book_now {
      display: flex !important;
      justify-content: flex-end !important;
      width: 100%;
  }

  .section3 .update-section p img {
      height: 220px;
      max-width: none;
      width: 300px;
  }

  .book_seva_button-1 {
      position: fixed;
      bottom: 5px;
      left: 0;
      z-index: 999999;
      width: 100%;
      max-width: 100%;
      border-radius: 20px 20px 0 0;
      background-color: #fff;
      padding: 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .book_seva_button-1 button {
      width: 100%;
      height: 34px;
      cursor: pointer;
      margin-top: 0px !important;
  }

  .details_puja {
      margin-top: 80px !important;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .seva_cards_live_image {
      width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* .champaign-tab .section1 .Welcome .DurgaMaa .live-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
} */.welcome_book_now {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
  }

  .champaign-tab .section1 .Welcome {
      width: auto;
  }

  .durga_mata_image .durga_mata_image_bg {
      width: 0;
  }
}

.MuiList-root span:nth-child(3) {
  color: #838383;
  margin-left: 15px;
  text-decoration: line-through;
  font-weight: normal;
}

.MuiList-root span:nth-child(1) {
  margin-left: 0px;
}

.MuiList-root span {
  margin-left: 5px;
  margin-right: 5px;
}
