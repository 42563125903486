.top-stepper {
    margin: 60px 0px 0px 0px;
}

.custom-amount-input {
    margin-top: 5px;
}

.rupee-symbol {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

/* styles start */
.Seva-Booking {
    background: #FCFFF8;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    /* Center the content */
}

.Seva-Booking img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    /* Add margin below the image */
}

.Seva-Booking .Seva-Booking-text {
    margin-bottom: 20px;
    /* Add margin below the text */
}

.Seva-Booking .Seva-Booking-text h5 {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.Seva-Booking .Seva-Booking-text p {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    /* Reset margin */
}

.Seva-Booking h6 {
    color: rgba(37, 37, 37, 0.80);
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px !important;
    /* Add margin below the h6 */
}

/* Adjust button styles as needed */
.Seva-Booking .MuiButton-root {
    border-radius: 80px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    width: 264px;
    height: 52px;
    display: inline-flex;
    /* Change to inline-flex */
    align-items: center;
    text-transform: capitalize;
    margin-bottom: 20px;
    /* Add margin below the button */
}

.Seva-Booking .MuiButton-root .MuiSvgIcon-root {
    margin-left: 15px;
    font-size: 20px;
}

/* Ensure the modal content is centered */
.Box-root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.MuiPaper-root {
    width: auto;
}

/* styles end */


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal1 {
    position: fixed;
    top: 25px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal-content {
    background-color: #FFF;
    padding: 20px;
    border-radius: 8px;
    width: 800px;
}

.modal-content1 {
    background-color: #FFF;
    padding: 20px;
    border-radius: 8px;
    width: 800px;

}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.amount-input_inputWrapper {
    position: relative;
}

.top-stepper .MuiStepConnector-horizontal .MuiStepConnector-line {
    display: none;
}

.top-stepper .MuiStepIcon-root.Mui-active {
    width: 150px !important;
    height: 10px;
    border-radius: 8px !important;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
}

.top-stepper .MuiStepIcon-root.Mui-completed {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
}

.top-stepper .MuiStepIcon-root {
    width: 150px !important;
    height: 10px;
    border-radius: 8px !important;
    background: #CFCFCF !important;
}

.top-stepper .MuiStepIcon-text {
    display: none;
}

.top-stepper .MuiStepLabel-label {
    display: none;
}

.top-stepper .MuiStep-root .MuiStepLabel-root {
    cursor: pointer !important;
}

.top-stepper .MuiStepIcon-root path {
    display: none;
}

.top-stepper .MuiStepIcon-root circle {
    display: none;
}

.checout_top {
    margin-top: 120px;
}

.checkout_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.checkout_title h2{
    color: #13151E;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin: 0px 0px 10px 0px;
}

.checkout_title p {
    color: #2c0202e3 !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    width: 900px;
    margin: 0px 0px 30px 0px;
}

.check_out_1 {
    margin-top: 80px;
}

.check_out_1 .mycart_title {
    margin-bottom: 10px;
}
.check_out_1 .mycart_title h3{
    font-family: "Poppins", sans-serif;
    color: #2C2C2C;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
}
.MuiMenuItem-gutters{
    color: #2C2C2C !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    font-family: "Poppins", sans-serif !important;
}

.mycart_bg {
    border-radius: 15px;
    border: 1px solid rgba(238, 238, 238, 0.80);
    padding: 20px;
    background: #fff;
}
.mycart_bg_seva_name {
    display: flex;
    justify-content: space-between;
}
.cart_menu{
    margin-top: 15px !important;
    border-top: 1px solid #0000001a;
    padding-top: 15px;
}
.mycart_bg_seva_name_list {
    display: flex;
    align-items: flex-start;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked{
    color: rgb(255, 91, 0) !important;
}
.css-1a5icme.Mui-checked{
    color: rgb(255, 91, 0) !important;
}
.mycart_bg_seva_name_list_item_1 {
    margin-left: 10px;
}
.prasadam_donate{
    margin-left: 0px !important;
}
.prasadam_donate .prasadam_content_span{
    font-family: "Quicksand", sans-serif;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0px 0px 0px 5x;
}
.prasadam_content{
    margin: 0px 0px 10px 0px;
}
.prasadam_donate .MuiCheckbox-root{
  padding: 0px !important;
}
.mycart_bg_seva_name_list_item_1 h3{
    font-family: "Poppins", sans-serif;
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 4px 0px;
}

.mycart_bg_seva_name_list_item_1 h4 {
    color: rgba(44, 44, 44, 0.75);
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0px 0px 6px 0px;
}

.mycart_bg_seva_name_list_item_1 h5 {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    margin: 0px 0px 05px 0px;
}

.mycart_bg_seva_name_list_item_1 h5 span {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.mycart_bg_seva_name_list_item_1 p {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    leading-trim: both;
    text-edge: cap;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 3px 0;
}
.mycart_remove button{
    color: #2C2C2C;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
}
.dakshana_bg h3{
    color: #2C2C2C;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 0px 10px;
}

.dakshana-select {
    margin-top: 20px;
    margin-bottom: 15px;
}

.dakshana-select .MuiInputLabel-formControl {
    color: #000 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.dakshana-select .MuiFormControlLabel-label{
    color: #000 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
}
.dakshana_bg.tip_bg{
    margin-top: 20px;
}

.dakshana-select .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
    border: unset;
}
.dakshana-select .MuiFormControl-fullWidth{
    width: 120px;
}

.dakshana-select .MuiSelect-select {
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.dakshana-select .MuiFormControl-root svg {
    fill: #E25704;
}

.dakshana-select .MuiFormControl-root fieldset:hover {
    border-color: unset;
}

.personal-info .MuiOutlinedInput-notchedOutline {
    border-radius: 6px;
    border: 2px solid rgb(26 25 25 / 14%) !important;
    border-color: rgb(26 25 25 / 14%) !important;
}

.personal-info .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f !important;
}

.MuiFormControl-root .MuiInputLabel-formControl.Mui-focused {
    color: #eb780473 !important;
    top: 0px !important;
}

.MuiFormControl-root .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #eb780473 !important;
}

.personal-info .MuiFormControl-root .MuiInputBase-formControl {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.personal-info #panel1-header {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    min-height: 0 !important;
}

.mycart_bg .MuiPaper-elevation1.Mui-expanded {
    margin-top: 10px !important;
}

.mycart_bg .MuiAccordion-gutters {
    box-shadow: unset !important;
    border-radius: 15px;
}

.mycart_bg .MuiAccordion-gutters::before {
    background-color: unset !important;
}

.mycart_bg .MuiAccordionSummary-contentGutters {
    margin: 0 !important;
}

.mycart_bg .MuiAccordionSummary-expandIconWrapper svg {
    fill: #A92902;
}

.personal-info .MuiFormControl-root {
    width: 100%;
}

.personal-info .MuiFormControl-root .MuiInputBase-formControl {
    height: 46px;
}

.personal-info .MuiFormControl-root .MuiInputLabel-animated {
    top: 0px;
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    overflow: visible  !important;
}
.personal-info #outlined-basic{
    color: #1E2833 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 10px 13px;
}

input:-internal-autofill-selected {
    background-color: none !important;
}

.summary-bg {
    border-radius: 15px;
    border: 1px solid rgba(238, 238, 238, 0.80);
    background: #FFF;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
    margin-top: 44px;
    padding: 20px;
}
.Product_bg{
    max-height: 345px;
    overflow-y: auto;
    min-height: 200px;

}
.summary-bg .summary_title{
    color: #2C2C2C;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.card-box {
    background: #fff;
    border-top: 1px solid #eeec;
    border-radius: 15px;
    box-shadow: 5px 5px 20px 0 #0000000d;
    padding: 12px 0;
}

.summary-bg .cost_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.summary-bg .cost_title:not(:first-child){
    margin-top: 20px;
}
.summary-bg .cost-title p {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.summary-bg .cost p {
    color: #242424;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.Product_bg {
    margin-top: 30px;
}

.product_item {
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.product_list_content {
    margin-left: 10px;
}
.product_list_content h3{
    color: #2C2C2C;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0px 0px 4px 0px;
}

.product_list_content p {
    color: #2c0202e3 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 0px 5px 0px;
}
.product_list{
    height: 110px;
    object-fit: fill;
    width: 100px;
}
.product_list img {
    width: 100%;
    height: 100%;
}
.product_list_content{
    width: calc(100% - 110px);
}
.ganga_jal_cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.ganga_jal_cost p {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}
.back_next_buttons {
    display: flex;
    align-items: center;
    margin: 30px 0px 60px 0px;
}

.back_next_buttons .back_button {
    width: 50%;
}
.back_next_buttons .back_button button{
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    width: 100%;
    leading-trim: both;
    text-edge: cap;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border-radius: 06px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: unset;
    display: flex;
    align-items: center;
}

.back_next_buttons .next_button button:disabled {
    background: #e0e0e0 !important;
    color: #898888;
}

.back_next_buttons .next_button {
    width: 50%;
}
.back_next_buttons .next_button button{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    width: 100%;
    border-radius: 06px;
    text-transform: unset;
    margin-left: 16px;
    display: flex;
    align-items: center;
}
.next_button1 button:disabled {
    background: #e0e0e0 !important;
    /* Set the background color when the button is disabled */
    color: #898888;

}

.back_next_buttons .next_button1 {
    width: 80%;
}
.back_next_buttons .next_button1 button{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    width: 100%;
    border-radius: 06px;
    text-transform: unset;
    margin-left: 16px;
    display: flex;
    align-items: center;
}
.right_sm_arrow {
    margin-left: 10px;
}

.left_sm_arrow {
    margin-right: 10px;
}

.right_symbol {
    margin-right: 10px;
}

.right_sm_arrow,
.left_sm_arrow,
.right_symbol {
    width: 18px;
}
.ganga_jal_cost_button1 .MuiButton-root {
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 94px;
    height: 37px;
    border-radius: 80px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 32px;
    text-transform: capitalize;
}

.ganga_jal_cost_button {
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF; */
    background-color: rgb(208 163 113 / 8%);
    color: #423b7d;
    border: .8px solid #e88648 !important;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
    border-radius: 6px;
    border: 3px solid #ccc;
    width: 120px;
    text-align: center;
    font-size: 16px;
    padding: 4px 0;
    cursor: pointer;
    margin-top: 10px;
}

.ganga_jal_cost_button .MuiButton-root {
    padding: 0;
    width: 30px;
    min-width: 0px;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #FFF;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ganga_jal_cost_button .puja_btn{
    font-size: 18px !important;
    width: 50px !important;
}

.ganga_jal_cost_button span {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    margin: 0;
}

.personal-info p {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 5px;
}

.personal_information_form {
    margin-top: 20px;
}

.mycart_bg .MuiAccordionSummary-contentGutters {
    display: flex;
    flex-direction: column;
}

.personal-info-content #panel1-header p {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 7px;
}

.personal-info .personal-info-mian {
    padding: 0px 0px 45px 30px;
}
.personal-info .personal-info-mian_2{
    padding-bottom: 10px;
}
.card-box_2{
    /* display: none; */
    margin-bottom: 20px;
}
.card-box-3{
    border-top: unset !important;
}
 .card-box_2 .personal-info #panel1-header{
    display: none;
 }
.personal-info-content .add-family {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 45px;
}

.personal-info-content .add-family .add-family-text {
    display: flex;
    align-items: center;
}

.personal-info-content .add-family .add-family-text h6 {
    color: #676767;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}

.personal-info-content .add-family .add-family-text h6 span {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.personal-info-content .add-family .add-family-text .MuiCheckbox-root {
    padding: 0px;
    color: #000;
}

.personal-info-content .add-family .add-family-text .MuiSvgIcon-root {
    border-radius: 6px;
}

.personal-info-content .add-family .add-family-text .Mui-checked {
    color: #E25704;

}

.personal-info-content .add-family .MuiButton-root {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    color: #676767;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 40.87px;
    border: 0.6px solid rgba(103, 103, 103, 0.40);
    text-transform: capitalize;
}

.personal-info-content .add-family .MuiButton-root img {
    margin-left: 10px;
}

.personal-info-content .add-family .active-btn {
    color: #FF5B00;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 40.87px;
    border: 0.6px solid #A92902;
    padding: 6px 20px;
    display: flex;
    align-items: center;
}

.personal-info-content .add-family .MuiButton-root .MuiSvgIcon-root {
    font-size: 19px;
    margin: 0px 0px 5px 5px;
}

.personal-info .addmore-family h5 {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    margin-right: 25px;
}

.personal-info .addmore-family {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.personal-info .addmore-family .MuiButton-root {
    border-radius: 40.87px;
    border: 1.277px solid #A92902;
    display: flex;
    padding: 8px 24px;
    align-items: center;
    color: #FF5B00;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.personal-info .addmore-family .MuiButton-root .MuiSvgIcon-root {
    margin: 0px 0px 0px 5px;
    font-size: 20px;
}

.personal-info-content .add-family .add-family-text-1 {
    display: flex;
    justify-content: flex-start;
}

.personal-info-content .add-family .add-family-text-1 h6 {
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
}

.personal-info-content .add-family .add-family-text-1 p {
    color: #505050;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.personal-info-content .add-family .add-family-text-1 .MuiCheckbox-root {
    padding: 0px;
    color: #000;
    margin-right: 10px;
    margin-bottom: 12px;
}

.personal-info-content .add-family .add-family-text-1 .MuiSvgIcon-root {
    border-radius: 6px;
}

.personal-info-content .add-family .add-family-text-1 .Mui-checked {
    color: #E25704;
}

.add-family button img {
    filter: grayscale();
}

.add-family .active-btn img {
    filter: unset;
}

.personal-info .add-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.personal-info .add-address .MuiButton-root {
    border-radius: 40.87px;
    border: 1.277px solid #A92902;
    display: flex;
    padding: 8px 24px;
    align-items: center;
    color: #FF5B00;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.personal-info .add-address .MuiButton-root .MuiSvgIcon-root {
    margin: 0px 0px 5px 5px;
    font-size: 20px;
}

.personal-info .add-address h5 {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    margin-right: 40px;
}

.personal-info-content .add-family .add-family-text-2 {
    display: flex;
    align-items: center;
}

.personal-info-content .add-family .add-family-text-2 h6 {
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;
}

.personal-info-content .add-family .add-family-text-2 .MuiCheckbox-root {
    padding: 0px;
    color: #000;
    margin-bottom: 3px;
}

.personal-info-content .add-family .add-family-text-2 .MuiSvgIcon-root {
    border-radius: 6px;
}

.personal-info-content .add-family .add-family-text-2 .Mui-checked {
    color: #E25704;

}

.personal-info-content input[type="checkbox" i] {
    border-radius: 50px !important;
}

.mycart_bg_seva_name_list_item {
    width: 200px;
}

.mycart_bg_seva_name_list_item img {
    width: 200px;
    height: 120px;
    object-fit: fill;
    border-radius: 10px;
}

.personal_info_check_out {
    display: flex;
    align-items: center;
}

.save_edit_check_out {
    font-family: "Quicksand", sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    text-transform: unset !important;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF !important;
    border-radius: 50px !important;
    padding: 03px 20px !important;
    border: 1px solid #e15604 !important;
    display: flex !important;
    align-items: center !important;
    margin-left: 10px !important;
}

.save_edit_check_out img {
    margin-left: 5px;
}

#menu-nakshatram .MuiMenu-paper {
    height: 400px;
    overflow-y: scroll;
}

.check_out_1 .modal1 .modal-content1 {
    height: 400px;
    overflow-y: scroll;
    padding: 30px 20px;
}

.check_out_1 .modal1 .modal-content1 h2 {
    margin: 0px 0px 10px 0px;
}

.check_out_1 .modal1 .personal-info {
    margin: 20px 0px 0px 0px;
    padding: 20px;
}

.check_out_1 .modal1 .personal-info .rest_save_button {
    margin-top: 10px;
}
.add_family_modal .modal-content h2, .check_out_1 .modal1 .modal-content1 h2{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
}
.add_family_modal .modal-content .personal-info_modal{
    margin-top: 20px;
}
.dakshana_bg_select{
    margin-top: 20px;
}
.dakshana_bg .dakshana_bg_select .css-14lo706{
    width: 120px;
}
.tip_bg .dakshana-select .css-14lo706{
    width: 96px;
}
.india_nonindia .MuiFormControlLabel-label{
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
}
.tip_amount{
    margin-bottom: 20px;
}
.tip_amount .MuiInputBase-input{
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 5px;
}

.tip_amount label{
    color: #000 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.tip_amount .MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
    border: 0.6px solid #A92902 !important;
} 
.donation_anonymous{
    width: 100% !important;
}
.donation_anonymous .MuiFormControlLabel-label{
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #2c0202e3 !important;
}
.donation_anonymous_buttons{
    margin: 0px 0px 30px 0px;
}
.donation_anonymous_main{
    display: flex;
    align-items: center;
    margin: 20px 0px 20px 10px;
}
.donation_anonymous_buttons .next_button1 button{
    margin-left: 0;
}
.donation_anonymous_main .MuiRadio-root{
    padding: 0px 5px 0px 0px !important;
}
/* .donation_anonymous_main .MuiFormControlLabel-labelPlacementEnd:nth-child(1){
    margin-right: 0 !important;
} */
.donation_anonymous_buttons .next_button1 button    {
    display: block;
}
.checkbox_for_anonymous .MuiFormControlLabel-label{
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: rgb(255, 91, 0) !important;
}
.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate{
    color: rgb(255, 91, 0) !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: rgb(255, 91, 0) !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: rgb(255, 91, 0) !important;
}
.ganga_jal_cost_button span{
    z-index: -9999;
}
.product_sm{
    display: none;
}
.product_lg{
    display: block;
}


@media (min-width:320px) and (max-width:767px) {
    .mobile-flex-reverse{
        display: flex;
        flex-direction: column-reverse;
    }
    .product_item {
        margin-top: 10px;
    }
    .checkout_title h2 {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 5px;
    }

    .checkout_title p {
        font-size: 15px;
        line-height: 25px;
        width: auto;
    }

    .top-stepper .MuiStepIcon-root {
        width: 70px !important;
    }

    .top-stepper .MuiStepIcon-root.Mui-active {
        width: 70px !important;
    }

    .check_out_1 {
        margin-top: 0;
    }

    .mycart_bg_seva_name_list_item img {
        width: 100%;
        height: 160px;
    }

    .mycart_bg_seva_name_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mycart_bg_seva_name_list_item_1 {
        margin: 10px 0px 0px 0px !important;
    }
    .prasadam_donate{
        margin: 0px 0px 0px 0px !important;
    }
    .mycart_remove {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .dakshana_bg h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .summary-bg {
        margin: 20px 0px 40px 0px;
    }

    .product_list_content {
        margin: 10px 0px 0px 0px;
    }

    .back_next_buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .back_next_buttons button {
        width: 100%;
    }

    .back_next_buttons .next_button button {
        margin: 0px 0px 0px 0px;
    }
    .back_next_buttons .back_button {
        width: 100%;
    }

    .back_next_buttons .next_button {
        margin: 10px 0px 0px 0px;
        width: 100%;
    }

    .personal-info-content .add-family {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .personal-info .personal-info-mian {
        padding: 0;
    }

    .personal-info-mian_edit_buton {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 20px;
    }

    .personal-info .addmore-family,
    .personal-info .add-address {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
    }

    .personal-info .addmore-family h5,
    .personal-info .add-address h5 {
        white-space: nowrap;
        margin: 0px 0px 10px 0px;
    }

    .personal-info-content .add-family .add-family-text-1 h6 {
        font-size: 16px;
        line-height: 26px;
    }

    .personal-info-content .add-family .add-family-text-2 h6 {
        font-size: 16px;
        line-height: 26px;
    }

    .mycart_bg_seva_name_list_item {
        width: 100%;
    }

    .mycart_bg_seva_name {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .checout_top{
        margin: 75px 0px 0px 0px !important;
        background: #f2f2f2;
    }
    .mycart_bg_seva_name_list_item_1 h3{
        font-size: 16px;
        line-height: 24px;
    }
    .mycart_bg_seva_name_list_item_1 h5 {
        margin-bottom: 5px;
    }
    .mycart_bg_seva_name_list_item_1 p{
        font-size: 16px;
        line-height: 28px;
    }
    .mycart_remove .MuiButton-root{
        padding: 0;
        margin-top: 05px;
    }
    .summary-bg .cost_title{
        margin-top: 10px;
    }
    .summary-bg .cost_title:not(:first-child){
        margin-top: 9px;
    }
    .top-stepper {
        margin-top: 40px;
    }
    .tip_bg{
        margin-top: 0px !important;
    }
    .dakshana-select{
        margin: 0 !important;
    }
    .dakshana_check_select{
        margin: 0px 0px 0px 10px !important;
    }
    .donation_anonymous_main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px 0px 10px 10px;
    }
    .donation_anonymous_buttons{
        margin: 0;
    }
    .donation_anonymous_main .MuiFormControlLabel-labelPlacementEnd{
        display: flex;
        align-items: flex-start !important;
    }
    .donation_anonymous_main .MuiFormControlLabel-labelPlacementEnd:nth-child(1){
        margin-bottom: 5px;
    }
    .donation_anonymous_buttons .next_button1 button{
        display: none;
    }
    .remove_sm{
        position: relative;
        top: -94px;
        right: -80%;
    }
    .product_sm{
        display: block;
    }
    .product_sm .summary-bg {
        margin: 20px 0px !important;
    }
    .product_lg{
        display: none;
    }
}

@media (min-width:320px) and (max-width:450px) {
    .save_edit_check_out {
        margin: 5px 0px 0px 0px !important;
    }

    .personal_info_check_out {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .modal-content1{
        width: 260px;
    }
    .add_family_modal .modal-content{
        width: 260px;   
    }
    .add_family_modal .modal-content{
        height: 400px;
        overflow-y: scroll;
        padding: 30px 20px;
    }
}
@media (min-width:400px) and (max-width:767px) {
    .product_list_content {
        margin: 0px 0px 0px 10px !important;;
    }
    .ganga_jal_cost{
        display: flex;
        flex-direction: row !important;
        align-items: center;
    }
}
@media (min-width:451px) and (max-width:600px) {
    .modal-content1{
        width: 360px;
    }
    .add_family_modal .modal-content{
        width: 360px;   
    }
    .add_family_modal .modal-content{
        height: 400px;
        overflow-y: scroll;
        padding: 30px 20px;
    }
}
@media (min-width:601px) and (max-width:767px) {
    .modal-content1{
        width: 460px;
    }
    .add_family_modal .modal-content{
        width: 460px;   
    }
    .add_family_modal .modal-content{
        height: 400px;
        overflow-y: scroll;
        padding: 30px 20px;
    }
}
@media (min-width:767px) and (max-width:900px) {
    .checout_top{
        margin: 90px 0px 0px 0px !important;
    }
    .mycart_bg_seva_name_list_item_1 h5 {
        margin-bottom: 5px;
    }
    .mycart_bg_seva_name_list_item_1 p{
        font-size: 16px;
        line-height: 28px;
    }
    .mycart_remove .MuiButton-root{
        padding: 0;
    }
    .summary-bg.cost_title{
        margin-top: 10px;
    }
    .summary-bg.cost_title:not(:first-child){
        margin-top: 9px;
    }
    .modal-content1{
        width: 460px;
    }
    .add_family_modal .modal-content{
        width: 460px;   
    }
    .add_family_modal .modal-content{
        height: 400px;
        overflow-y: scroll;
        padding: 30px 20px;
    }
    .mycart_bg_seva_name_list{
        flex-direction:row !important;
        align-items: flex-start !important;
    }
    .mycart_bg_seva_name_list_item_1{
        margin: 0px 0px 0px 10px !important;
    }
    .prasadam_donate{
        margin: 0px 0px 0px 0px !important;
    }
    .donation_anonymous_main .MuiFormControlLabel-labelPlacementEnd:nth-child(1){
        margin-right: 20px !important;
    }
    .donation_anonymous_buttons{
        margin: 0;
    }
    .summary-bg{
        margin: 20px 0px 40px 0px;
    }
    .donation_anonymous_buttons .next_button1 button{
        display: none;
    }
}

@media (min-width:767px) and (max-width:991px) {
    .top-stepper .MuiStepIcon-root {
        width: 120px !important;
    }

    .top-stepper .MuiStepIcon-root.Mui-active {
        width: 120px !important;
    }

    .checkout_title p {
        font-size: 15px;
        line-height: 25px;
        width: auto;
    }

    .summary-bg {
        margin-top: 20px;
    }
    .mycart_bg_seva_name_list{
        flex-direction: column;
        align-items: flex-start;
    }
    .mycart_bg_seva_name_list_item_1{
        margin: 05px 0px 0px 0px;
    }
    .mycart_bg_seva_name_list_item_1 h3{
        font-size: 16px;
        line-height: 24px;
    }
    .tip_bg {
        margin-top: 15px !important;
    }
}
@media (min-width:901px) and (max-width:1199px) {
    .rest_save_button{
        width: 100%;
    }
    .donation_anonymous_main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .donation_anonymous_buttons{
        margin: 0px 0px 30px 0px;
    }
}