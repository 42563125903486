.product_item_bg:not(:last-child) {
    margin-right: 10px;
}

.product_item_bg {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    padding: 15px;
    transition: 0.3s all ease-in-out;
    -moz-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    box-shadow: 5px 5px 20px 0 #0000000d;
    /* min-height: 120px;
    height: 120px; */
    /* overflow-y: scroll; */
}
/* .product_item_bg p::-webkit-scrollbar {
    width: 5px;               
  }
 
  .product_item_bg p::-webkit-scrollbar-track {
    background:#FFF;       
  }
 
  .product_item_bg p::-webkit-scrollbar-thumb {
    background-color: #f49004;    
    border-radius: 20px;      
    border: 2px solid #FFF;  
  } */
.product_item_bg:hover {
    box-shadow: 0px 5px 52px 1px rgba(0, 0, 0, 0.2);
}
.product_item_bg_main_image{
    width: 100%;
    height: 180px;
    object-fit: fill;
    border-radius: 6px;
    border: 1px solid #EEE;
    padding: 3px;
}
.product_item_bg img {
    width: 100%;
    height: 100%;
}
.product_item_bg{
    display: flex;
    flex-direction: column;
    /* height: 100%; */
}
.product_item_bg_main_content{
    margin-top: 10px;
}

.product_item_bg h3 {
    color: #2c0202;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 23px;
    margin: 0px 0px 5px 0px;
}

.product_item_bg p {
    color: #2c0202e3 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0px 0px 10px 0px;
    /* min-height: 120px;
    height: 120px;
    overflow-y: scroll; */
}

.product_item_bg h4 span{
    font-family: "Quicksand", sans-serif;
    margin-right: 2px;
}
.product_item_bg h4 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.product_item_bg .ganga_jal_cost_button {
   border-radius: 4px;
   height: 44px;
}
.product_percentage {
    margin: 10px 0px 20px 0px;
}
.product_percentage h3 {
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.product_percentage_count {
    width: 100%;
    height: 12px;
    border-radius: 85.463px;
    background: #F1F1F1;
    position: relative;
    margin-top: 5px;
}

.product_percentage_count_child {
    position: absolute;
    width: 40%;
    height: 100%;
    border-radius: 85.463px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%), #0043CE;
    top: 0;
    left: 0;
}

.product_percentage_count_child_1 {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%), #0043CE;
    top: 4px;
    left: 50%;
}

.product_percentage_count_child_2 {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%), #0043CE;
    top: 4px;
    right: 15%;
}

.details_donation_scroller .champaign-tab .special-offer-content p {
    width: auto;
}

.details_donation_scroller .champaign-tab .nav button {
    white-space: nowrap;
}

.details_donation_scroller .champaign-tab .section2 .video-section {
    display: block;
    align-items: flex-start;
    padding: 15px;
}

.details_donation_scroller .champaign-tab .section2 .video-section .video_section_image img {
    width: 100%;
    height: 440px;
}

.details_donation_scroller .champaign-tab .section2 .video-section .video_section_image button img {
    width: 60px;
    height: 60px;
}

.details_donation_scroller .champaign-tab .section2 .video-section p {
    text-align: left;
    width: auto;
}
.product_product_item_price{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ganga_jal_cost_button .minus_btn{
    margin-right: 10px;
}
.ganga_jal_cost_button .plus_btn{
    margin-left: 10px;
}
.product_product_item_price_mb{
    display: none;
}
.product_product_item_price_lg{
    display: block;
}
@media (min-width:768px) {
    .summary-sm{
        display:none;
    }
}
@media (min-width:320px) and (max-width:767px) {
    .donation_champaign_tab .product_item{
        margin-bottom: 20px !important;
    }
    .summary-bg{
        padding: 15px;
        display:none;
    }
    .summary-sm {
        display: block;
        padding: 15px;
        box-shadow: none;
        border-top: 1px solid #eeec;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    .product_list{
        display: flex;
        align-items: center;
    }
    .product_list{
        width: unset;
        height: unset;
    }
    .product_list img{
        width: 80px;
        height: 80px;
    }
    .product_list_content{
        width: calc(100% - 90px);
        margin-left: 10px;
    }
    .product_product_item_price{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .ganga_jal_cost_button{
        margin: 0px 0px 0px 0px;
        width: 100px;
        height: 40px;
    }
    .ganga_jal_cost_button button span{
        height: 0;
    }
    .product_item_bg p{
        min-height: unset;
        overflow: visible;
        height: unset;
    }
    .product_item_bg p::-webkit-scrollbar {
        width: unset;               /* width of the entire scrollbar */
      }
     
      .product_item_bg p::-webkit-scrollbar-track {
        background:unset;        /* color of the tracking area */
      }
     
      .product_item_bg p::-webkit-scrollbar-thumb {
        background-color: unset;    /* color of the scroll thumb */
        border-radius:unset;       /* roundness of the scroll thumb */
        border:unset;  /* creates padding around scroll thumb */
      }
      .product_product_item_price_mb{
        display: block !important;
        margin-bottom: 10px;
      }
      .product_product_item_price_lg{
        display: none;
      }
}
@media (min-width:320px) and (max-width:991px) {
    .product_item_bg:hover{
        box-shadow:unset !important;
    }
}
@media (min-width:320px) and (max-width:767px) {
    .details_donation_scroller .champaign-tab .section2 .video-section {
        margin-top: 20px;
    }
    .product_item_bg_main_image{
        height: 190px;
    }
}
@media (min-width:768px) and (max-width:900px) {
    .product_product_item_price{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .ganga_jal_cost_button {
        margin: 0px 0px 0px 0px;
        width: 200px;
        height: 40px;
    }
}

@media (min-width:1300px) and (max-width:20000px) {
    .new_foundation_construct_bg{
        width: 430px;
    }
}